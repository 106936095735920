<template>
    <div class="refund-histories">
        <div class="page-header">
            <StackRouterHeaderBar />
            <div class="title m-b-40" v-html="$translate('REFUND_HISTORIES')" />
            <div class="refund-process">
                <div class="process f-14 c-black" v-html="$translate('REFUND_PROCESS')" />
                <div class="process-notices m-t-16">
                    <div
                        class="notice"
                        v-for="idx in 3"
                        :key="idx"
                        v-html="$translate(`REFUND_PROCESS_NOTICE_${idx}`)"
                    />
                </div>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th class="created-at">{{ 'DATE' | translate }}</th>
                    <th class="relative-user">{{ 'DATING_TARGET' | translate }}</th>
                    <th class="agent">{{ 'AGENT' | translate }}</th>
                    <th class="status">{{ 'STATUS' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr :key="refund.id" v-for="refund in refunds">
                    <td class="created-at">{{ $moment(refund.created_at).format('YY.MM.DD') }}</td>
                    <td class="relative-user">{{ refund.relative_user.name }}</td>
                    <td class="agent">{{ (refund.agent || {}).name }}</td>
                    <td
                        class="status"
                        :class="{
                            'c-danger': refund.status === 10,
                            'c-primary': statusString(refund) === '환급 완료',
                        }"
                        v-html="statusString(refund)"
                    />
                </tr>
                <tr v-if="!refunds" class="empty">
                    <td colspan="4">{{ 'REFUNDS_EMPTY' | translate }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'RefundHistoriesPage',
    computed: {
        refunds() {
            return this.$store.getters.refunds
        },
    },
    mounted() {
        this.$store.dispatch('loadRefunds')
    },
    methods: {
        statusString(refund) {
            const { status, agent } = refund

            if (status === 10) return '환급 거절'
            if (status === 0) {
                if (!agent) {
                    // 내친소는 주선자가 없으므로 예외처리 필요
                    return '<p>주선자</p><p>확인중</p>'
                } else {
                    if (!agent.vacation) return `<p>주선자</p><p>확인중(휴식)</p>`

                    return '<p>주선자</p><p>확인중</p>'
                }
            }
            if ([2, 4].indexOf(status) !== -1) return '<p>운영팀</p><p>심사중</p>'

            return '환급 완료'
        },
    },
}
</script>
<style scoped lang="scss"></style>
